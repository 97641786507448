var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "multiselect",
        {
          attrs: {
            label: "value",
            "track-by": "key",
            placeholder: "Type to search",
            "open-direction": "bottom",
            options: _vm.foundOptions,
            multiple: _vm.multiple,
            searchable: true,
            loading: _vm.isLoading,
            "internal-search": false,
            "clear-on-select": _vm.clearOnSelect,
            "close-on-select": !_vm.multiple,
            "options-limit": 300,
            limit: 3,
            "limit-text": _vm.limitText,
            "max-height": 600,
            "show-no-results": false,
            "hide-selected": true
          },
          on: { "search-change": _vm.find },
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
            _vm._v("No results found")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }